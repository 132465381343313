export default {
  createConfig({redirect_uri, client_id, authority, storage = "localStorage"}) {
    return {
      /**
       * @description: the uri that's whitelisted in AM for OAuth2
       */
      redirect_uri: redirect_uri,
      /**
       * @description: Your application client id
       */
      client_id: client_id,
      /**
       * @description: The url of the authority, e.g https://accounts.maersk.com/developer
       */
      authority: authority,
      storage: {
        /**
         * @default: "localStorage"
         * @description: the type of storage you want to utilize to store the tokens
         * choices are sessionStoragesession lived tokens, and localStorage, for permanent storing (until deleted)
         */
        type: storage,
      },
      /**
       * default: "iamAL"
       * this will give the AL instance name in the global scope (window.iamAL)
       */
      // window_namespace: window_namespace,
      /**
       * default: "iamAL"
       * this will give the AL instance name in the global scope (window.iamAL)
       */
    };
  },
};