import { environmentNames } from "./static/environmentNames";
import { environmentsMap } from "./static/environmentsMap";

export default class Environments {
  constructor() {
    const env = environmentsMap[window.location.host];

    const DEFAULT_VALUES = {
      AUTHORITY: "https://accounts-cdt.maersk.com/thirdparty-cdt",
      CLIENT_ID: "thirdparty",
      LOGOUT_URI: "https://accounts-cdt.maersk.com/thirdparty-cdt/auth/logout",

      COOKIE_ID_TOKEN_STORE_NAME: "rcmlpMaerskUser",
      COOKIE_ACCESS_TOKEN_STORE_NAME: "thirdparty.rcmapps.access_token",
      COOKIE_REFRESH_TOKEN_STORE_NAME: "thirdparty.rcmapps.refresh_token",
      COOKIE_EXPIRY_DATE_STORE_NAME: "thirdparty.rcmapps.expiry_date",

      REDIRECT_PATH: "login-callback",
      BASE_URI: window.location.origin,
      ORIGINAL_URL_PARAM_NAME: "rcmAppsOriginalURI",
    };

    let OVERRIDES = {};
    switch (env) {
      case environmentNames.DEV:
      case environmentNames.TEST:
        break;
      case environmentNames.PREPROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts-stage.maersk.com/thirdparty-stage",
          LOGOUT_URI: "https://accounts-stage.maersk.com/thirdparty-stage/auth/logout",
        };
        break;
      case environmentNames.PROD:
        OVERRIDES = {
          AUTHORITY: "https://accounts.maersk.com/thirdparty",
          LOGOUT_URI: "https://accounts.maersk.com/thirdparty/auth/logout",
        };
        break;
      default:
        throw new Error(`Unknown Environment ${env}`);
    }
    this.configs = Object.assign(DEFAULT_VALUES, OVERRIDES);
  }
  config(config_name) {
    return this.configs[config_name];
  }
}
