import { names } from "./static";

import LoginView from "../views/LoginView.vue";
import RedirectView from "../views/RedirectView.vue";
import LogoutView from "../views/LogoutView.vue";

const routes = [
  {
    path: "/",
    name: names.login,
    component: LoginView,
  },
  {
    path: "/login-callback",
    name: names.redirect,
    component: RedirectView,
  },
  {
    path: "/logout",
    name: names.logout,
    component: LogoutView,
  },
  {
    path: "*",
    component: LoginView,
  },
];

export default routes;
