import { environmentNames } from "./environmentNames";

export const environmentsMap = Object.freeze({
  ["rcmecdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["rcmecintdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["gensetdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["vcmdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["digitalamdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["rcmdevelopment-cdt.maersk.com"]: environmentNames.DEV,
  ["wccdevelopment-cdt.maersk.com"]: environmentNames.DEV,

  ["rcmec-cdt.maersk.com"]: environmentNames.TEST,
  ["rcmecint-cdt.maersk.com"]: environmentNames.TEST,
  ["genset-cdt.maersk.com"]: environmentNames.TEST,
  ["vcm-cdt.maersk.com"]: environmentNames.TEST,
  ["digitalam-cdt.maersk.com"]: environmentNames.TEST,
  ["rcm-cdt.maersk.com"]: environmentNames.TEST,
  ["ccdcrp-cdt.maersk.com"]: environmentNames.TEST,
  ["wcc-cdt.maersk.com"]: environmentNames.TEST,

  ["rcmec-stage.maersk.com"]: environmentNames.PREPROD,
  ["rcmecint-stage.maersk.com"]: environmentNames.PREPROD,
  ["genset-stage.maersk.com"]: environmentNames.PREPROD,
  ["vcm-stage.maersk.com"]: environmentNames.PREPROD,
  ["digitalam-stage.maersk.com"]: environmentNames.PREPROD,
  ["digitalamcpt-stage.maersk.com"]: environmentNames.PREPROD,
  ["rcm-stage.maersk.com"]: environmentNames.PREPROD,
  ["rcmcpt-stage.maersk.com"]: environmentNames.PREPROD,
  ["ccdcrp-stage.maersk.com"]: environmentNames.PREPROD,
  ["wcc-stage.maersk.com"]: environmentNames.PREPROD,

  ["rcmec.maersk.com"]: environmentNames.PROD,
  ["rcmecint.maersk.com"]: environmentNames.PROD,
  ["genset.maersk.com"]: environmentNames.PROD,
  ["vcm.maersk.com"]: environmentNames.PROD,
  ["digitalam.maersk.com"]: environmentNames.PROD,
  ["rcm.maersk.com"]: environmentNames.PROD,
  ["ccdcrp.maersk.com"]: environmentNames.PROD,
  ["wcc.maersk.com"]: environmentNames.PROD,
});
